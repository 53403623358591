import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Property, Location } from './app.models';
import { AppSettings } from './app.settings';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from './shared/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './shared/alert-dialog/alert-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { BoatListResponse, BoatResponse } from './shared/models';

export class Data {
  constructor(
    public properties: Property[],
    public compareList: Property[],
    public favorites: Property[],
    public locations: Location[]
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  Data = new Data(
    [], // properties
    [], // compareList
    [], // favorites
    [] // locations
  );

  url = environment.url + '/assets/data/';
  apiKey = 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I';

  constructor(
    private readonly http: HttpClient,
    private readonly appSettings: AppSettings,
    private readonly dialog: MatDialog,
    private readonly bottomSheet: MatBottomSheet,
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  getProperties(): Observable<Property[]> {
    return this.http.get<Property[]>(this.url + 'properties.json');
  }

  getPropertyById(id: number | string): Observable<Property> {
    return this.http.get<Property>(this.url + 'property-' + id + '.json');
  }

  getFeaturedProperties(): Observable<Property[]> {
    return this.http.get<Property[]>(this.url + 'featured-properties.json');
  }

  getRelatedProperties(): Observable<Property[]> {
    return this.http.get<Property[]>(this.url + 'related-properties.json');
  }

  getPropertiesByAgentId(agentId: string): Observable<Property[]> {
    return this.http.get<Property[]>(this.url + 'properties-agentid-' + agentId + '.json');
  }

  getLocations(): Observable<Location[]> {
    return this.http.get<Location[]>(this.url + 'locations.json');
  }

  getAddress(lat = 40.714224, lng = -73.961452) {
    return this.http.get(
      'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=' + this.apiKey
    );
  }

  getLatLng(address) {
    return this.http.get(
      'https://maps.googleapis.com/maps/api/geocode/json?key=' + this.apiKey + '&address=' + address
    );
  }

  getFullAddress(lat = 40.714224, lng = -73.961452) {
    return this.http
      .get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=' + this.apiKey)
      .subscribe(data => {
        return data['results'][0]['formatted_address'];
      });
  }

  addToCompare(property: Property, component, direction) {
    if (!this.Data.compareList.filter(item => item.id == property.id)[0]) {
      this.Data.compareList.push(property);
      this.bottomSheet
        .open(component, {
          direction: direction,
        })
        .afterDismissed()
        .subscribe(isRedirect => {
          if (isRedirect) {
            if (isPlatformBrowser(this.platformId)) {
              window.scrollTo(0, 0);
            }
          }
        });
    }
  }

  addToFavorites(property: Property, direction) {
    if (!this.Data.favorites.filter(item => item.id == property.id)[0]) {
      this.Data.favorites.push(property);
      this.snackBar.open('The property "' + property.title + '" has been added to favorites.', '×', {
        verticalPosition: 'top',
        duration: 3000,
        direction: direction,
      });
    }
  }

  openConfirmDialog(title: string, message: string) {
    const dialogData = new ConfirmDialogModel(title, message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
    });
    return dialogRef;
  }

  openAlertDialog(message: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      maxWidth: '400px',
      data: message,
    });
    return dialogRef;
  }

  getTranslateValue(key: string, param: string = null) {
    let value = null;
    this.translateService.get(key, { param: param }).subscribe((res: string) => {
      value = res;
    });
    return value;
  }

  getCities() {
    return [
      { id: 1, name: 'New York' },
      { id: 2, name: 'Chicago' },
      { id: 3, name: 'Los Angeles' },
      { id: 4, name: 'Seattle' },
    ];
  }

  getNeighborhoods() {
    return [
      { id: 1, name: 'Astoria', cityId: 1 },
      { id: 2, name: 'Midtown', cityId: 1 },
      { id: 3, name: 'Chinatown', cityId: 1 },
      { id: 4, name: 'Austin', cityId: 2 },
      { id: 5, name: 'Englewood', cityId: 2 },
      { id: 6, name: 'Riverdale', cityId: 2 },
      { id: 7, name: 'Hollywood', cityId: 3 },
      { id: 8, name: 'Sherman Oaks', cityId: 3 },
      { id: 9, name: 'Highland Park', cityId: 3 },
      { id: 10, name: 'Belltown', cityId: 4 },
      { id: 11, name: 'Queen Anne', cityId: 4 },
      { id: 12, name: 'Green Lake', cityId: 4 },
    ];
  }

  getStreets() {
    return [
      { id: 1, name: 'Astoria Street #1', cityId: 1, neighborhoodId: 1 },
      { id: 2, name: 'Astoria Street #2', cityId: 1, neighborhoodId: 1 },
      { id: 3, name: 'Midtown Street #1', cityId: 1, neighborhoodId: 2 },
      { id: 4, name: 'Midtown Street #2', cityId: 1, neighborhoodId: 2 },
      { id: 5, name: 'Chinatown Street #1', cityId: 1, neighborhoodId: 3 },
      { id: 6, name: 'Chinatown Street #2', cityId: 1, neighborhoodId: 3 },
      { id: 7, name: 'Austin Street #1', cityId: 2, neighborhoodId: 4 },
      { id: 8, name: 'Austin Street #2', cityId: 2, neighborhoodId: 4 },
      { id: 9, name: 'Englewood Street #1', cityId: 2, neighborhoodId: 5 },
      { id: 10, name: 'Englewood Street #2', cityId: 2, neighborhoodId: 5 },
      { id: 11, name: 'Riverdale Street #1', cityId: 2, neighborhoodId: 6 },
      { id: 12, name: 'Riverdale Street #2', cityId: 2, neighborhoodId: 6 },
      { id: 13, name: 'Hollywood Street #1', cityId: 3, neighborhoodId: 7 },
      { id: 14, name: 'Hollywood Street #2', cityId: 3, neighborhoodId: 7 },
      { id: 15, name: 'Sherman Oaks Street #1', cityId: 3, neighborhoodId: 8 },
      { id: 16, name: 'Sherman Oaks Street #2', cityId: 3, neighborhoodId: 8 },
      { id: 17, name: 'Highland Park Street #1', cityId: 3, neighborhoodId: 9 },
      { id: 18, name: 'Highland Park Street #2', cityId: 3, neighborhoodId: 9 },
      { id: 19, name: 'Belltown Street #1', cityId: 4, neighborhoodId: 10 },
      { id: 20, name: 'Belltown Street #2', cityId: 4, neighborhoodId: 10 },
      { id: 21, name: 'Queen Anne Street #1', cityId: 4, neighborhoodId: 11 },
      { id: 22, name: 'Queen Anne Street #2', cityId: 4, neighborhoodId: 11 },
      { id: 23, name: 'Green Lake Street #1', cityId: 4, neighborhoodId: 12 },
      { id: 24, name: 'Green Lake Street #2', cityId: 4, neighborhoodId: 12 },
    ];
  }

  getFeatures() {
    return [
      { id: 1, name: 'Air Conditioning', selected: false },
      { id: 2, name: 'Barbeque', selected: false },
      { id: 3, name: 'Dryer', selected: false },
      { id: 4, name: 'Microwave', selected: false },
      { id: 5, name: 'Refrigerator', selected: false },
      { id: 6, name: 'TV DVD', selected: false },
      { id: 7, name: 'Sauna', selected: false },
      { id: 8, name: 'WiFi', selected: false },
      { id: 9, name: 'Fireplace', selected: false },
      { id: 10, name: 'Swimming Pool', selected: false },
      { id: 11, name: 'Gym', selected: false },
    ];
  }

  getEvents() {
    return [
      { id: 1, name: 'Birthday Parties', selected: false },
      { id: 2, name: 'Corporate Events', selected: false },
      { id: 3, name: 'Weddings', selected: false },
      { id: 4, name: 'Vivid Sydney', selected: false },
      { id: 5, name: 'Australia Day', selected: false },
      { id: 6, name: 'New Years Day', selected: false },
      { id: 7, name: 'New Years Eve - Private Hire', selected: false },
      { id: 8, name: 'New Years Eve - Cruise Tickets', selected: false },
    ];
  }

  getCharterTimes() {
    return [
      { id: 1, name: 'Morning', selected: false },
      { id: 2, name: 'Afternoon', selected: false },
      { id: 3, name: 'Evening', selected: false },
      { id: 4, name: 'All Day', selected: false },
      { id: 5, name: 'Overnight', selected: false },
    ];
  }

  getCateringPreferences() {
    return [
      { id: 1, name: 'Canapes', selected: false },
      { id: 2, name: 'Buffet', selected: false },
      { id: 3, name: 'Formal Dining', selected: false },
      { id: 4, name: 'Unsure About Food', selected: false },
      { id: 5, name: 'BYO Food', selected: false },
    ];
  }

  getBeveragePreferences() {
    return [
      { id: 1, name: 'Beverage Package', selected: false },
      { id: 2, name: 'Unsure About Beverage', selected: false },
      { id: 3, name: 'BYO Beverage', selected: false },
    ];
  }

  getApproxBudgets() {
    return [
      { id: 1, name: 'up to $2,000', selected: false },
      { id: 2, name: '$2,000 - $4,000', selected: false },
      { id: 3, name: '$4,000 - $8,000', selected: false },
      { id: 4, name: '$8,000 - $10,000', selected: false },
      { id: 5, name: '> $10,000', selected: false },
      { id: 6, name: '> $50,000', selected: false },
    ];
  }

  getActivities() {
    return [
      { id: 1, name: 'Overnight Boat Stays', selected: false },
      { id: 2, name: 'Water Transfers', selected: false },
      { id: 3, name: 'Team Building', selected: false },
      { id: 4, name: 'Meeting and Conference Rooms', selected: false },
      { id: 5, name: 'Private Fishing Charters', selected: false },
    ];
  }

  getHomeCarouselSlides() {
    return this.http.get<any[]>(this.url + 'slides.json');
  }

  filterData(data, params: any, sort?, page?, perPage?) {
    if (params) {
      if (params.propertyType) {
        data = data.filter(property => property.propertyType == params.propertyType.name);
      }

      if (params.propertyStatus && params.propertyStatus.length) {
        let statuses = [];
        params.propertyStatus.forEach(status => {
          statuses.push(status.name);
        });
        let properties = [];
        data.filter(property =>
          property.propertyStatus.forEach(status => {
            if (statuses.indexOf(status) > -1) {
              if (!properties.includes(property)) {
                properties.push(property);
              }
            }
          })
        );
        data = properties;
      }

      if (params.price) {
        if (this.appSettings.settings.currency == 'USD') {
          if (params.price.from) {
            data = data.filter(property => {
              if (property.priceDollar.sale && property.priceDollar.sale >= params.price.from) {
                return true;
              }
              if (property.priceDollar.rent && property.priceDollar.rent >= params.price.from) {
                return true;
              }
              return false;
            });
          }
          if (params.price.to) {
            data = data.filter(property => {
              if (property.priceDollar.sale && property.priceDollar.sale <= params.price.to) {
                return true;
              }
              if (property.priceDollar.rent && property.priceDollar.rent <= params.price.to) {
                return true;
              }
              return false;
            });
          }
        }
        if (this.appSettings.settings.currency == 'EUR') {
          if (params.price.from) {
            data = data.filter(property => {
              if (property.priceEuro.sale && property.priceEuro.sale >= params.price.from) {
                return true;
              }
              if (property.priceEuro.rent && property.priceEuro.rent >= params.price.from) {
                return true;
              }
              return false;
            });
          }
          if (params.price.to) {
            data = data.filter(property => {
              if (property.priceEuro.sale && property.priceEuro.sale <= params.price.to) {
                return true;
              }
              if (property.priceEuro.rent && property.priceEuro.rent <= params.price.to) {
                return true;
              }
              return false;
            });
          }
        }
      }

      if (params.city) {
        data = data.filter(property => property.city == params.city.name);
      }

      if (params.zipCode) {
        data = data.filter(property => property.zipCode == params.zipCode);
      }

      if (params.neighborhood && params.neighborhood.length) {
        let neighborhoods = [];
        params.neighborhood.forEach(item => {
          neighborhoods.push(item.name);
        });
        let properties = [];
        data.filter(property =>
          property.neighborhood.forEach(item => {
            if (neighborhoods.indexOf(item) > -1) {
              if (!properties.includes(property)) {
                properties.push(property);
              }
            }
          })
        );
        data = properties;
      }

      if (params.street && params.street.length) {
        let streets = [];
        params.street.forEach(item => {
          streets.push(item.name);
        });
        let properties = [];
        data.filter(property =>
          property.street.forEach(item => {
            if (streets.indexOf(item) > -1) {
              if (!properties.includes(property)) {
                properties.push(property);
              }
            }
          })
        );
        data = properties;
      }

      if (params.bedrooms) {
        if (params.bedrooms.from) {
          data = data.filter(property => property.bedrooms >= params.bedrooms.from);
        }
        if (params.bedrooms.to) {
          data = data.filter(property => property.bedrooms <= params.bedrooms.to);
        }
      }

      if (params.bathrooms) {
        if (params.bathrooms.from) {
          data = data.filter(property => property.bathrooms >= params.bathrooms.from);
        }
        if (params.bathrooms.to) {
          data = data.filter(property => property.bathrooms <= params.bathrooms.to);
        }
      }

      if (params.garages) {
        if (params.garages.from) {
          data = data.filter(property => property.garages >= params.garages.from);
        }
        if (params.garages.to) {
          data = data.filter(property => property.garages <= params.garages.to);
        }
      }

      if (params.area) {
        if (params.area.from) {
          data = data.filter(property => property.area.value >= params.area.from);
        }
        if (params.area.to) {
          data = data.filter(property => property.area.value <= params.area.to);
        }
      }

      if (params.yearBuilt) {
        if (params.yearBuilt.from) {
          data = data.filter(property => property.yearBuilt >= params.yearBuilt.from);
        }
        if (params.yearBuilt.to) {
          data = data.filter(property => property.yearBuilt <= params.yearBuilt.to);
        }
      }

      if (params.features) {
        let arr = [];
        params.features.forEach(feature => {
          if (feature.selected) arr.push(feature.name);
        });
        if (arr.length > 0) {
          let properties = [];
          data.filter(property =>
            property.features.forEach(feature => {
              if (arr.indexOf(feature) > -1) {
                if (!properties.includes(property)) {
                  properties.push(property);
                }
              }
            })
          );
          data = properties;
        }
      }
    }

    //for show more properties mock data
    for (var index = 0; index < 2; index++) {
      data = data.concat(data);
    }

    this.sortData(sort, data);
    return this.paginator(data, page, perPage);
  }

  sortData(sort, data) {
    if (sort) {
      switch (sort) {
        case 'Newest':
          data = data.sort((a, b) => {
            return <any>new Date(b.published) - <any>new Date(a.published);
          });
          break;
        case 'Oldest':
          data = data.sort((a, b) => {
            return <any>new Date(a.published) - <any>new Date(b.published);
          });
          break;
        case 'Popular':
          data = data.sort((a, b) => {
            if (a.ratingsValue / a.ratingsCount < b.ratingsValue / b.ratingsCount) {
              return 1;
            }
            if (a.ratingsValue / a.ratingsCount > b.ratingsValue / b.ratingsCount) {
              return -1;
            }
            return 0;
          });
          break;
        case 'Price (Low to High)':
          if (this.appSettings.settings.currency == 'USD') {
            data = data.sort((a, b) => {
              if ((a.priceDollar.sale || a.priceDollar.rent) > (b.priceDollar.sale || b.priceDollar.rent)) {
                return 1;
              }
              if ((a.priceDollar.sale || a.priceDollar.rent) < (b.priceDollar.sale || b.priceDollar.rent)) {
                return -1;
              }
              return 0;
            });
          }
          if (this.appSettings.settings.currency == 'EUR') {
            data = data.sort((a, b) => {
              if ((a.priceEuro.sale || a.priceEuro.rent) > (b.priceEuro.sale || b.v.rent)) {
                return 1;
              }
              if ((a.priceEuro.sale || a.priceEuro.rent) < (b.priceEuro.sale || b.priceEuro.rent)) {
                return -1;
              }
              return 0;
            });
          }
          break;
        case 'Price (High to Low)':
          if (this.appSettings.settings.currency == 'USD') {
            data = data.sort((a, b) => {
              if ((a.priceDollar.sale || a.priceDollar.rent) < (b.priceDollar.sale || b.priceDollar.rent)) {
                return 1;
              }
              if ((a.priceDollar.sale || a.priceDollar.rent) > (b.priceDollar.sale || b.priceDollar.rent)) {
                return -1;
              }
              return 0;
            });
          }
          if (this.appSettings.settings.currency == 'EUR') {
            data = data.sort((a, b) => {
              if ((a.priceEuro.sale || a.priceEuro.rent) < (b.priceEuro.sale || b.v.rent)) {
                return 1;
              }
              if ((a.priceEuro.sale || a.priceEuro.rent) > (b.priceEuro.sale || b.priceEuro.rent)) {
                return -1;
              }
              return 0;
            });
          }
          break;
        default:
          break;
      }
    }
    return data;
  }

  paginator(items, page?, perPage?) {
    var page = page || 1,
      perPage = perPage || 4,
      offset = (page - 1) * perPage,
      paginatedItems = items.slice(offset).slice(0, perPage),
      totalPages = Math.ceil(items.length / perPage);
    return {
      data: paginatedItems,
      pagination: {
        page: page,
        perPage: perPage,
        prePage: page - 1 ? page - 1 : null,
        nextPage: totalPages > page ? page + 1 : null,
        total: items.length,
        totalPages: totalPages,
      },
    };
  }

  getTestimonials() {
    return [
      {
        text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
        author: 'Mr. Adam Sandler',
        position: 'General Director',
        image: 'assets/images/profile/adam.jpg',
      },
      {
        text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
        author: 'Ashley Ahlberg',
        position: 'Housewife',
        image: 'assets/images/profile/ashley.jpg',
      },
      {
        text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
        author: 'Bruno Vespa',
        position: 'Blogger',
        image: 'assets/images/profile/bruno.jpg',
      },
      {
        text: 'Donec molestie turpis ut mollis efficitur. Nam fringilla libero vel dictum vulputate. In malesuada, ligula non ornare consequat, augue nibh luctus nisl, et lobortis justo ipsum nec velit. Praesent lacinia quam ut nulla gravida, at viverra libero euismod. Sed tincidunt tempus augue vitae malesuada. Vestibulum eu lectus nisi. Aliquam erat volutpat.',
        author: 'Mrs. Julia Aniston',
        position: 'Marketing Manager',
        image: 'assets/images/profile/julia.jpg',
      },
    ];
  }

  getOurTeam() {
    return [
      {
        text1:
          '• Responsibilities: Charter and Superyacht consulting for a fleet of over 140 boats, ensuring customer satisfaction and quality service at all times and managing the sales of all event types including corporate charters, private functions, weddings and more.',
        text2:
          '• Qualifications and Experience: Years of hospitality experience including the management of varying restaurants and event spaces, as well as a strong sales and customer service background garnered from a range of varying roles within the sales and hospitality industries.',
        author: 'Shani',
        position: 'CHARTER AND SUPERYACHT SPECIALIST',
        image: 'assets/images/profile/adam.jpg',
      },
      {
        text1:
          '• Responsibilities: Website management, digital promotions, photo and video editing, content management, email marketing, social media and customer service.',
        text2:
          '• Qualifications and Experience: Bachelor of Information Technology, Diploma in Multimedia ,experience in website development, digital designing, online marketing',
        author: 'Anup',
        position: 'DIGITAL MARKETING SPECIALIST',
        image: 'assets/images/profile/ashley.jpg',
      },
    ];
  }

  getClients() {
    return [
      { name: 'aloha', image: 'assets/images/clients/aloha.png' },
      { name: 'dream', image: 'assets/images/clients/dream.png' },
      { name: 'congrats', image: 'assets/images/clients/congrats.png' },
      { name: 'best', image: 'assets/images/clients/best.png' },
      { name: 'original', image: 'assets/images/clients/original.png' },
      { name: 'retro', image: 'assets/images/clients/retro.png' },
      { name: 'king', image: 'assets/images/clients/king.png' },
      { name: 'love', image: 'assets/images/clients/love.png' },
      { name: 'the', image: 'assets/images/clients/the.png' },
      { name: 'easter', image: 'assets/images/clients/easter.png' },
      { name: 'with', image: 'assets/images/clients/with.png' },
      { name: 'special', image: 'assets/images/clients/special.png' },
      { name: 'bravo', image: 'assets/images/clients/bravo.png' },
    ];
  }

  getBoatDetails(id): Observable<[]> {
    return this.http.get<[]>(environment.boaturl + '/boatInfo/' + id);
  }

  getBoatDetailsWithDisplayId(displayId: string): Observable<BoatResponse> {
    return this.http.get<BoatResponse>(environment.boaturl + '/boatInfo/getWithDisplayId/' + displayId);
  }

  getBoatsWithBriefInfo(queryParam, pageNum: number, pageSize: number): Observable<BoatListResponse> {
    return this.http.post<BoatListResponse>(environment.boaturl + '/boatInfo/listWithBriefInfo', {
      queryParam,
      pageNum,
      pageSize,
    });
  }

  getRecommendBoatList(queryParam, pageNum, pageSize): Observable<[]> {
    return this.http.post<[]>(environment.boaturl + '/boatInfo/listRecommendBoats', {
      queryParam: queryParam,
      pageNum: pageNum,
      pageSize: pageSize,
    });
  }
}
