import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Blog } from 'src/app/pages/blogs/models';

@Component({
  selector: 'app-blog-grid',
  templateUrl: './blog-grid.component.html',
  styleUrls: ['./blog-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlogGridComponent {
  @Input() blogs: Blog[] = [];
}
