import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';

export const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
        data: { breadcrumb: 'Home' },
      },
      {
        path: 'about',
        loadChildren: () => import('./pages/about/about.module').then(m => m.AboutModule),
        data: { breadcrumb: 'About Us' },
      },
      {
        path: 'contact',
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
        data: { breadcrumb: 'Contact' },
      },
      {
        path: 'boats',
        loadChildren: () => import('./pages/boats/boats.module').then(m => m.BoatsModule),
        data: { breadcrumb: 'Boats' },
      },
      {
        path: 'blogs',
        loadChildren: () => import('./pages/blogs/blogs.module').then(m => m.BlogsModule),
        data: { breadcrumb: 'Blogs' },
      },
      {
        path: 'agents',
        loadChildren: () => import('./pages/agents/agents.module').then(m => m.AgentsModule),
        data: { breadcrumb: 'Agents' },
      },
      {
        path: 'favorites',
        loadChildren: () => import('./pages/favorites/favorites.module').then(m => m.FavoritesModule),
        data: { breadcrumb: 'Favorites' },
      },
      {
        path: 'compare',
        loadChildren: () => import('./pages/compare/compare.module').then(m => m.CompareModule),
        data: { breadcrumb: 'Compare' },
      },
      {
        path: 'pricing',
        loadChildren: () => import('./pages/pricing/pricing.module').then(m => m.PricingModule),
        data: { breadcrumb: 'Pricing' },
      },
      {
        path: 'faq',
        loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule),
        data: { breadcrumb: 'FAQ' },
      },
      {
        path: 'how',
        loadChildren: () => import('./pages/how-to-hire/how-to-hire.module').then(m => m.HowToHireModule),
        data: { breadcrumb: 'How To Hire' },
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
        data: { breadcrumb: 'Login' },
      },
      {
        path: 'register',
        loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule),
        data: { breadcrumb: 'Register' },
      },
      {
        path: 'terms-conditions',
        loadChildren: () =>
          import('./pages/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsModule),
        data: { breadcrumb: 'Terms & Conditions' },
      },
      {
        path: 'account',
        loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule),
        data: { breadcrumb: 'Account' },
      },
      {
        path: 'submit-property',
        loadChildren: () => import('./pages/submit-property/submit-property.module').then(m => m.SubmitPropertyModule),
        data: { breadcrumb: 'Submit Property' },
      },
    ],
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule),
    data: { breadcrumb: 'Landing' },
  },
  { path: 'lock-screen', component: LockScreenComponent, data: { breadcrumb: 'Lock Screen' } },
  { path: '**', component: NotFoundComponent, data: { breadcrumb: '404 Not Found' } },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
