import { createAction } from '@ngrx/store';
import { BlogResponse } from '../models';

export namespace BlogsActions {
  const BLOGS_LOAD_DATA = '[Blogs] Load data';
  const BLOGS_LOAD_DATA_COMPLETE = '[Blogs] Load data complete';
  const BLOGS_LOAD_DATA_FAIL = '[Blogs] Load data fail';
  const BLOGS_RESET_STATE = '[Blogs] Reset state';

  export const loadBlogs = createAction(BLOGS_LOAD_DATA);

  export const blogsLoadComplete = createAction(BLOGS_LOAD_DATA_COMPLETE, (blogs: BlogResponse) => ({
    blogs,
  }));

  export const blogsLoadFail = createAction(BLOGS_LOAD_DATA_FAIL);

  export const resetState = createAction(BLOGS_RESET_STATE);
}
