import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { HelperService } from 'src/app/shared/services';
import { BlogResponse } from '../models';
import { BlogsActions } from './blogs.actions';
import { BlogsService } from '../services/blogs.service';

@Injectable()
export class BlogsEffects {
  loadBlogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlogsActions.loadBlogs),
      switchMap(() =>
        this.blogsService.fetchBlogs().pipe(map((data: BlogResponse) => BlogsActions.blogsLoadComplete(data)))
      ),
      catchError(() => {
        this.helperService.setErrorMessage('Sorry, failed to fetch blogs. Please try to reload the page.');

        return EMPTY;
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly blogsService: BlogsService,
    private readonly helperService: HelperService
  ) {}
}
