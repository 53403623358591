import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Blog, BlogResponse } from '../models';

@Injectable({
  providedIn: 'root',
})
export class BlogsService {
  protected readonly apiUrl = environment.boaturl;

  constructor(private readonly http: HttpClient) {}

  fetchBlogs() {
    return this.http.get<BlogResponse>(`${this.apiUrl}/blog/list`);
  }
}
