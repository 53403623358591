import { Action, createReducer, on } from '@ngrx/store';
import { LoadingState, loadingStateDefault } from 'src/app/shared/models';
import { Blog, BlogResponse } from '../models';
import { BlogsActions } from './blogs.actions';

export const BLOGS_FEATURE_KEY = 'blogs';

export interface BlogsState extends LoadingState {
  readonly blogs: BlogResponse;
}

export interface BlogsPartialState {
  readonly [BLOGS_FEATURE_KEY]: BlogsPartialState;
}

const initialState: BlogsState = {
  ...loadingStateDefault,
  blogs: null,
};

const blogsReducer = createReducer(
  initialState,
  on(BlogsActions.blogsLoadFail, state => ({
    ...state,
    isLoading: false,
    boatDetails: null,
    isErrored: true,
  })),
  on(BlogsActions.blogsLoadComplete, (state, { blogs }) => ({
    ...state,
    isLoading: false,
    isErrored: false,
    blogs,
  })),
  on(BlogsActions.resetState, state => ({
    ...state,
    isLoading: false,
    isErrored: false,
    blogs: null,
  }))
);

export function reducer(state: BlogsState, action: Action) {
  return blogsReducer(state, action);
}
